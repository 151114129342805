import logo from "./logo.svg";
import "./App.css";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

function App() {
  const [defaultAverageSelection, setDefaultAverageSelection] = useState("");
  const [totalKapadInCm, setTotalKapadInCm] = useState(0);
  const componentRef = useRef(null);
  const [skuName, setSkuName] = useState("");
  const [calculatedSize, setCalculatedSize] = useState([
    {
      size: "S",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
    {
      size: "M",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
    {
      size: "L",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
    {
      size: "XL",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
    {
      size: "XXL",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
    {
      size: "3XL",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
    {
      size: "4XL",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
    {
      size: "5XL",
      shirt: 0,
      chadda: 0,
      pair: 0,
    },
  ]);
  const [avgDefaultSize, setAvgDefaultSize] = useState({
    size_s_shirt: 107,
    size_m_shirt: 107,
    size_l_shirt: 107,
    size_xl_shirt: 112,
    size_xxl_shirt: 112,
    size_3xl_shirt: 120,
    size_4xl_shirt: 120,
    size_5xl_shirt: 120,
    chadda_avg: 58,
  });

  const handleCalculateKapad = () => {
    const totalCloth = Number(totalKapadInCm);
    // S Size
    const size_shirt_s = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_s_shirt)
    );
    const size_chadda_s = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_s = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_s_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );
    // M Size
    const size_shirt_m = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_m_shirt)
    );
    const size_chadda_m = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_m = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_m_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );
    // L Size
    const size_shirt_l = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_l_shirt)
    );
    const size_chadda_l = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_l = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_l_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );
    // XL Size
    const size_shirt_xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_xl_shirt)
    );
    const size_chadda_xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_xl = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_xl_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );
    // XXL Size
    const size_shirt_xxl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_xxl_shirt)
    );
    const size_chadda_xxl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_xxl = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_xxl_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );
    // 3XL Size
    const size_shirt_3xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_3xl_shirt)
    );
    const size_chadda_3xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_3xl = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_3xl_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );
    // 4XL Size
    const size_shirt_4xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_4xl_shirt)
    );
    const size_chadda_4xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_4xl = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_4xl_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );
    // 5XL Size
    const size_shirt_5xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.size_5xl_shirt)
    );
    const size_chadda_5xl = Number(
      Number(totalCloth) / Number(avgDefaultSize?.chadda_avg)
    );
    const size_pair_5xl = Number(
      Number(totalCloth) /
        Number(
          Number(
            Number(avgDefaultSize?.size_5xl_shirt) +
              Number(avgDefaultSize?.chadda_avg)
          )
        )
    );

    setCalculatedSize([
      {
        size: "S",
        shirt: size_shirt_s,
        chadda: size_chadda_s,
        pair: size_pair_s,
      },
      {
        size: "M",
        shirt: size_shirt_m,
        chadda: size_chadda_m,
        pair: size_pair_m,
      },
      {
        size: "L",
        shirt: size_shirt_l,
        chadda: size_chadda_l,
        pair: size_pair_l,
      },
      {
        size: "XL",
        shirt: size_shirt_xl,
        chadda: size_chadda_xl,
        pair: size_pair_xl,
      },
      {
        size: "XXL",
        shirt: size_shirt_xxl,
        chadda: size_chadda_xxl,
        pair: size_pair_xxl,
      },
      {
        size: "3XL",
        shirt: size_shirt_3xl,
        chadda: size_chadda_3xl,
        pair: size_pair_3xl,
      },
      {
        size: "4XL",
        shirt: size_shirt_4xl,
        chadda: size_chadda_4xl,
        pair: size_pair_4xl,
      },
      {
        size: "5XL",
        shirt: size_shirt_5xl,
        chadda: size_chadda_5xl,
        pair: size_pair_5xl,
      },
    ]);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle: "@page { size: auto; margin: 12mm 5mm; } }",
    // print: async (printIframe) => {
    //   // Do whatever you want here, including asynchronous work
    //   // get generated pdf content from generatePDFFunction function
    //   // await generatePDFFunction(printIframe);
    // },
  });
  return (
    <>
      <Box sx={{ padding: "20px" }}>
        <Box>
          <Box
            onClick={() => {
              if (defaultAverageSelection == "shirts") {
                setDefaultAverageSelection("");
              } else {
                setDefaultAverageSelection("shirts");
              }
            }}
            sx={{
              backgroundColor: "#cdcdcd",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <Typography
              component="div"
              sx={{ fontWeight: 700, fontSize: "large" }}
            >
              Average For Shirts in Centimeter (1 m = 100 cm)
            </Typography>
          </Box>
          {defaultAverageSelection == "shirts" && (
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="S size Avg"
                  placeholder="S size Avg"
                  value={avgDefaultSize?.size_s_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_s_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="M size Avg"
                  placeholder="M size Avg"
                  value={avgDefaultSize?.size_m_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_m_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="L size Avg"
                  placeholder="L size Avg"
                  value={avgDefaultSize?.size_l_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_l_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="XL size Avg"
                  placeholder="XL size Avg"
                  value={avgDefaultSize?.size_xl_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_xl_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="XXL size Avg"
                  placeholder="XXL size Avg"
                  value={avgDefaultSize?.size_xxl_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_xxl_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="3XL size Avg"
                  placeholder="3XL size Avg"
                  value={avgDefaultSize?.size_3xl_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_3xl_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="4XL size Avg"
                  placeholder="4XL size Avg"
                  value={avgDefaultSize?.size_4xl_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_4xl_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="5XL size Avg"
                  placeholder="5XL size Avg"
                  value={avgDefaultSize?.size_5xl_shirt}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      size_5xl_shirt: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Divider />
        <Box sx={{ margin: "10px 0" }}>
          <Box
            onClick={() => {
              if (defaultAverageSelection == "chadda") {
                setDefaultAverageSelection("");
              } else {
                setDefaultAverageSelection("chadda");
              }
            }}
            sx={{
              backgroundColor: "#cdcdcd",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            <Typography
              component="div"
              sx={{ fontWeight: 700, fontSize: "large" }}
            >
              Average For chadda in Centimeter (1 m = 100 cm)
            </Typography>
          </Box>
          {defaultAverageSelection == "chadda" && (
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  label="Chadda Avg"
                  placeholder="Chadda Avg"
                  value={avgDefaultSize?.chadda_avg}
                  onChange={(e) => {
                    setAvgDefaultSize({
                      ...avgDefaultSize,
                      chadda_avg: Number(e.target.value),
                    });
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    classes: {
                      notchedOutline: "rounded",
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Divider />
        <Box>
          <Box>
            <h3>Total Kapad in Centimeter (1 m = 100 cm)</h3>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="number"
                label="Total Kapad"
                placeholder="Total Kapad"
                value={totalKapadInCm}
                onChange={(e) => {
                  setTotalKapadInCm(Number(e.target.value));
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  classes: {
                    notchedOutline: "rounded",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          onClick={() => {
            // handleCofirmModalOpen(true);
            // setDeleteData(data?._id);
            handleCalculateKapad();
          }}
          sx={{
            padding: "5px 10px",
            marginRight: 1,
            borderRadius: "30px",
            margin: "10px 0",
            padding: "10px 20px",
            fontSize: "12px",
            background: "black",
            color: "white",
            "&:hover": {
              background: "black",
              color: "white",
            },
          }}
        >
          Calculate
        </Button>
      </Box>
      <Box
        sx={{ padding: "20px" }}
        ref={componentRef}
        id="quotation"
        className="print"
      >
        <Button
          onClick={() => {
            handlePrint();
          }}
          sx={{
            padding: "5px 10px",
            marginRight: 1,
            borderRadius: "30px",
            margin: "10px 0",
            padding: "10px 20px",
            fontSize: "12px",
            background: "#A44CD3",
            fontWeight: 700,
            color: "white",
            "&:hover": {
              background: "#A44CD3",
              color: "white",
            },
          }}
        >
          Print
        </Button>
        <TextField
          variant="outlined"
          fullWidth
          label="Product SKU"
          placeholder="Product SKU"
          sx={{ margin: "10px 0" }}
          value={skuName}
          onChange={(e) => {
            setSkuName(e.target.value);
          }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            classes: {
              notchedOutline: "rounded",
            },
          }}
        />
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead sx={{ backgroundColor: "#242424", color: "white" }}>
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontWeight: 600, fontSize: "1rem" }}
                >
                  Size
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: 600, fontSize: "1rem" }}
                  align="center"
                >
                  Shirt
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: 600, fontSize: "1rem" }}
                  align="center"
                >
                  Chadda
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontWeight: 600, fontSize: "1rem" }}
                  align="center"
                >
                  Pair
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calculatedSize.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ fontSize: "14px", fontWeight: 700 }}
                    component="th"
                    scope="row"
                  >
                    {row?.size}
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px", fontWeight: 700 }}
                    align="center"
                  >
                    {Number(row?.shirt)?.toFixed(2)}
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px", fontWeight: 700 }}
                    align="center"
                  >
                    {Number(row?.chadda)?.toFixed(2)}
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "14px", fontWeight: 700 }}
                    align="center"
                  >
                    {Number(row?.pair)?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default App;
